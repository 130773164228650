import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export const ResetPasswordDescription = () => (
  <>
    <Typography variant="h4" component="h1" paragraph>
      Forgot your password?
    </Typography>

    <Typography variant="body2" component="p">
      No problem. Use your Picardy account email address, and we'll email you a
      link that you can use to create a new password.
    </Typography>

    <Typography variant="body2" component="p">
      Questions? Visit our <Link href="/help">HELP</Link> section or{' '}
      <Link href="/contact">CONTACT US</Link> directly.
    </Typography>
  </>
);

export default ResetPasswordDescription;
