import { UseQueryOptions, useQuery } from 'react-query';
import { RequestOptions, Variables } from 'graphql-request';

import { useGlobalAlert } from '../common/components/GlobalAlert';
import { request } from '../api/client';

export function useGraphqlQuery<T extends any, V extends Variables = {}>(
  graphqlRequest: RequestOptions<V, T>,
  {
    errorMessage,
    onError,
    ...useQueryOptions
  }: Omit<UseQueryOptions<unknown, unknown, T>, 'queryFn'> & {
    errorMessage: string;
  },
): ReturnType<typeof useQuery<unknown, unknown, T>> {
  const { update: updateGlobalAlert } = useGlobalAlert();
  return useQuery({
    ...useQueryOptions,
    queryFn: async () => {
      const resp = await request(graphqlRequest);
      if (!resp) throw new Error('graphql request resulted in no response');
      return resp;
    },
    onError: err => {
      console.error(err);
      updateGlobalAlert({
        severity: 'error',
        content: errorMessage,
      });
      if (typeof onError === 'function') return onError(err);
    },
  });
}
