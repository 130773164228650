import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { renderReactApp } from '../util/react';
import AppWrapper from '../common/components/AppWrapper';
import LoginApp from './components/LoginApp';

renderReactApp(
  <AppWrapper>
    <Router basename="/login">
      <LoginApp />
    </Router>
  </AppWrapper>,
);
