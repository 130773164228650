import React, { FunctionComponent, FormHTMLAttributes, ReactNode } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert, { AlertProps } from '@material-ui/lab/Alert';

import { RightCardActions } from '../../../common/components/CardExtras';

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  submitCTA: string;
  children?: ReactNode;
  buttonColor?: ButtonProps['color'];
  title?: string;
  onCancel?: () => void;
  cancelCTA?: string;
  formMessage?: FormMessage;
  formMessageAnchorOrigin?: SnackbarOrigin;
  onCloseFormMessage?: () => void;
  disabled?: boolean;
}

export interface FormMessage {
  message: string | null;
  type: AlertProps['severity'] | null;
}

export const INIT_FORM_MESSAGE: FormMessage = { message: null, type: null };

const CardForm: FunctionComponent<Props> = ({
  children,
  submitCTA,
  buttonColor = 'primary',
  title,
  onCancel,
  cancelCTA = 'Cancel',
  formMessage = INIT_FORM_MESSAGE,
  formMessageAnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  onCloseFormMessage,
  disabled,
  ...props
}: Props) => (
  <form noValidate autoComplete="on" {...props}>
    <CardContent>
      {title && (
        <Typography
          variant="h5"
          component="h2"
          style={{ marginBottom: '0.5em' }}
        >
          {title}
        </Typography>
      )}
      <Snackbar
        anchorOrigin={formMessageAnchorOrigin}
        open={!!formMessage.message}
        autoHideDuration={10000}
        onClose={onCloseFormMessage}
      >
        <Alert
          onClose={onCloseFormMessage}
          severity={formMessage.type || 'info'}
        >
          {formMessage.message}
        </Alert>
      </Snackbar>
      {children}
    </CardContent>
    <RightCardActions>
      <Button
        disabled={disabled}
        style={{ textAlign: 'right', marginBottom: '0.5em' }}
        variant="contained"
        size="large"
        type="submit"
        color={buttonColor}
      >
        {submitCTA}
      </Button>
      {onCancel && (
        <Button
          disabled={disabled}
          style={{ textAlign: 'right', marginBottom: '0.5em' }}
          size="large"
          type="button"
          onClick={onCancel}
        >
          {cancelCTA}
        </Button>
      )}
    </RightCardActions>
  </form>
);

export default CardForm;
