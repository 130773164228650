import { gql } from 'graphql-request';

const API_ROOT = '/api/user';

export type UserRoles = 'superuser' | 'teacher' | 'teacher_pending' | 'student';
export interface UserModel {
  _id: string;
  name: string;
  email: string;
  roles: { [R in UserRoles]: boolean };
  facebookId: string;
  googleId: string;
  premium: boolean;
  premiumType: string;
  expiration?: {
    day: string;
    date: string;
  };
}

export const USER_CONTENT = gql`
  _id
  name
  email
  roles {
    superuser
    teacher
    teacher_pending
    student
  }
  facebookId
  googleId
  premium
  expiration {
    day
    date
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      ${USER_CONTENT}
    }
  }
`;

export async function deleteMe(): Promise<void> {
  const resp = await fetch(`${API_ROOT}/me`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (resp.ok) return undefined;
  throw new Error(await resp.text());
}

export async function updateUser(updatedUser: UserModel): Promise<UserModel> {
  const resp = await fetch(`${API_ROOT}/${updatedUser._id}`, {
    method: 'POST',
    body: JSON.stringify({ user: updatedUser }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (resp.ok) return (await resp.json()).user;
  throw new Error(await resp.text());
}

type GenerateResetPasswordRequest = { email: string };
export async function generateResetPassword(
  payload: GenerateResetPasswordRequest,
): Promise<void> {
  const resp = await fetch(`${API_ROOT}/generateResetPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: payload }),
  });

  if (resp.ok) return await resp.json();
  throw new Error(await resp.text());
}

type ResetPasswordRequest = { password: string; resetPasswordToken: string };
export async function resetPassword(
  payload: ResetPasswordRequest,
): Promise<void> {
  const resp = await fetch(`${API_ROOT}/resetPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: payload,
    }),
  });

  if (resp.ok) return await resp.json();
  throw new Error(await resp.text());
}

type CreatePasswordRequest = {
  password: string;
};
export async function createPassword(
  userId: string,
  payload: CreatePasswordRequest,
): Promise<void> {
  const resp = await fetch(`${API_ROOT}/createPassword/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: payload,
    }),
  });

  if (resp.ok) return await resp.json();
  throw new Error(await resp.text());
}

type UpdatePasswordRequest = {
  oldPassword: string;
  password: string;
};
export async function updatePassword(
  userId: string,
  payload: UpdatePasswordRequest,
): Promise<void> {
  const resp = await fetch(`${API_ROOT}/updatePassword/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: payload,
    }),
  });

  if (resp.ok) return await resp.json();
  throw new Error(await resp.text());
}

export type SocialAccount = 'googleId' | 'facebookId';
export async function unlinkSocialLogin(
  userId: string,
  socialAccount: SocialAccount,
) {
  const resp = await fetch(`${API_ROOT}/unlink/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: { key: socialAccount },
    }),
  });

  if (resp.ok) return (await resp.json()).user;
  throw new Error(await resp.text());
}
