// Like https://github.com/brunobertolini/styled-by
// TODO: write types for this mess
export function styledBy<P = object, T = string>(
  property: keyof P,
  mapping: Record<PropertyKey, T>,
  defaultValue: T,
) {
  return (props: P): T => {
    const prop = (props[property] as unknown) as string;
    if (mapping.hasOwnProperty(prop)) {
      return (mapping[prop] as unknown) as T;
    }
    return defaultValue;
  };
}
