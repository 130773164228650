export type Validator<T extends object = {}> = (
  input: any,
  formValues: T,
) => string | void;

export const validators: Record<string, Validator> = {
  name(input: string): string | void {
    if (!input) return 'Please enter your name.';
    return undefined;
  },

  email(input: string): string | void {
    if (!input) return 'Please enter your email.';
    return undefined;
  },

  password(input: string): string | void {
    if (!input) return 'Please enter a password.';
    return undefined;
  },

  confirmPassword(
    input: string,
    formValues: { password?: string },
  ): string | void {
    if (!input) return 'Please confirm the password.';
    if (validators.password(input, formValues))
      return validators.password(input, formValues);
    if (input !== formValues.password) return 'Passwords must match.';
    return undefined;
  },

  required(input: string): string | void {
    if (!input) return 'This field is required.';
    return undefined;
  },
};

export function isFormValid<T extends object>(formValues: T) {
  return Object.keys(formValues).every(key => {
    if (!validators.hasOwnProperty(key)) {
      return true;
    }

    return !validators[key](formValues[key as keyof T], formValues);
  });
}
