import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const TermsReminder = () => (
  <Typography
    variant="body2"
    component="span"
    style={{ margin: '1em 1em 0.5em', display: 'inline-block' }}
  >
    By logging in to Picardy, you agree to our&nbsp;
    <Link href="/legal">Terms of Service</Link> and&nbsp;
    <Link href="/legal">Privacy Policy</Link>.
  </Typography>
);

export default TermsReminder;
