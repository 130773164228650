import React, { FunctionComponent } from 'react';
import {
  withRouter,
  Link as RouterLink,
  RouteComponentProps,
} from 'react-router-dom';
import queryString from 'query-string';
import { colors, overridePrimaryColor } from '@picardy/picardy-design-system';
import { styled } from '@material-ui/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGoogle,
  faFacebook,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';

import { useQueryParams } from '../../../../hooks/router';
import StyledSnackbarContent from '../../../../common/components/StyledSnackbarContent';
import TermsReminder from '../TermsReminder';
import { USERNAME_LOGIN_PATH } from '../../../constants';
import { authUrl } from '../../../utils';

const LoginButton = styled(Button)({
  borderRadius: 0,
  width: '100%',
});

function SocialLoginButton({
  children,
  buttonColor,
  faIcon,
  ...props
}: ButtonProps & {
  buttonColor: string;
  faIcon: IconDefinition;
}) {
  return (
    <MuiThemeProvider theme={overridePrimaryColor(buttonColor)}>
      <LoginButton size="large" color="primary" variant="contained" {...props}>
        <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faIcon} />
        {children}
      </LoginButton>
    </MuiThemeProvider>
  );
}

export const GoogleLoginButton = (props: ButtonProps) => (
  <SocialLoginButton buttonColor={colors.google} faIcon={faGoogle} {...props} />
);

export const FacebookLoginButton = (props: ButtonProps) => (
  <SocialLoginButton
    buttonColor={colors.facebook}
    faIcon={faFacebook}
    {...props}
  />
);

const UsernameLoginLink: FunctionComponent = props => {
  const params = useQueryParams();
  let redirectTo: string | undefined;
  if (params.has('redirect_to')) {
    redirectTo = params.get('redirect_to')!;
  }
  return (
    <RouterLink to={authUrl(USERNAME_LOGIN_PATH, redirectTo)} {...props} />
  );
};

const SocialLogin: FunctionComponent = () => {
  const params = useQueryParams();
  const hasLoginError = params.has('loginFailed');
  let redirectTo: string | undefined;
  if (params.has('redirect_to')) {
    redirectTo = params.get('redirect_to')!;
  }

  return (
    <Paper>
      {hasLoginError && (
        <StyledSnackbarContent
          variant="error"
          message="Login failed. Please try again. If this persists, please contact us."
        />
      )}
      <List>
        <ListItem>
          <ListItemText secondary="You can log in to Picardy with your Facebook or Google account." />
        </ListItem>

        <ListItem>
          <FacebookLoginButton href={authUrl('/auth/facebook', redirectTo)}>
            Log In with Facebook
          </FacebookLoginButton>
        </ListItem>

        <ListItem>
          <GoogleLoginButton href={authUrl('/auth/google', redirectTo)}>
            Log In with Google
          </GoogleLoginButton>
        </ListItem>

        <ListItem divider aria-hidden />

        <ListItem button component={UsernameLoginLink}>
          <ListItemText primary="Log in or sign up with a username" />
        </ListItem>

        <ListItem divider button component="a" href="/demo">
          <ListItemText primary="Try out our demo" />
        </ListItem>

        <ListItem>
          <ListItemText secondary={<TermsReminder />} />
        </ListItem>
      </List>
    </Paper>
  );
};

export { default as Description } from '../LoginDescription';

export default SocialLogin;
