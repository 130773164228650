import React, { createContext, useContext, ReactNode } from 'react';
import { useQuery } from 'react-query';

import { GET_CURRENT_USER, UserModel } from '../../../api/user';
import LoadingPage from '../../../common/components/LoadingPage';
import { useGraphqlQuery } from '../../../hooks/api';

export const UserContext = createContext<[UserModel | void, () => void]>([
  undefined,
  () => {
    throw new Error('UserProvider did not initialize the context.');
  },
]);

export function UserProvider({ children }: { children: ReactNode }) {
  const { data, isLoading, refetch } = useGraphqlQuery<{
    currentUser: UserModel | void;
  }>(
    { document: GET_CURRENT_USER },
    {
      queryKey: ['user', 'me'],
      errorMessage: 'We were unable to get your user data.',
      retry: false,
    },
  );

  return (
    <UserContext.Provider
      value={[data ? data.currentUser : undefined, refetch]}
    >
      {isLoading ? <LoadingPage /> : children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext)[0];
export const useUserState = () => useContext(UserContext);
