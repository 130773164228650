import queryString from 'query-string';

export function authUrl(
  url: string,
  redirect_to: string | string[] = '/dashboard',
): string {
  const [, origSearch] = url.split('?');
  const params = queryString.parse(origSearch);
  return `${url}?${queryString.stringify({ ...params, redirect_to })}`;
}
