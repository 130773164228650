import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function LoadingPage() {
  return (
    <Grid item sm={12} style={{ marginTop: '-1.75em' }}>
      <LinearProgress />
    </Grid>
  );
}
