import React, { useState, EventHandler, SyntheticEvent } from 'react';
import { get } from 'lodash';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { Validator } from '../../../util/validators';

export type StatefulTextFieldProps<
  T extends object,
  K = keyof T,
> = TextFieldProps & {
  name: K; // this can't be `keyof T` all the time because of things like "references[0]"
  formValues: T;
  formTouched?: boolean;
  validator?: Validator;
  onChange?: EventHandler<SyntheticEvent>;
  onBlur?: EventHandler<SyntheticEvent>;
  variant?: 'outlined' | 'contained';
  valueGetter?: (values: T, key: K) => any;
};

function StatefulTextField<
  T extends object = Record<string, unknown>,
  K = keyof T,
>({
  name,
  formValues,
  onChange = () => {},
  onBlur = () => {},
  validator = () => {},
  formTouched = false,
  variant = 'outlined',
  helperText: providedHelperText = null,
  valueGetter = get,
  ...props
}: StatefulTextFieldProps<T, K>) {
  const [value, setValue] = useState(valueGetter(formValues, name) || '');
  const [internalTouched, setTouched] = useState(formTouched);

  const touched = internalTouched || formTouched;
  const validatorText = validator(value, formValues); // Add type assertion
  const invalid = touched && !!validatorText;

  // write validation messages over any existing validator message
  let helperText = providedHelperText;
  if (invalid && typeof validatorText === 'string') {
    helperText = validatorText;
  }

  return (
    <TextField
      id={name}
      name={name}
      variant={variant}
      margin="normal"
      fullWidth
      error={invalid}
      helperText={helperText}
      value={value}
      onChange={event => {
        setValue(event.target.value);
        return onChange(event);
      }}
      onBlur={event => {
        setTouched(true);
        return onBlur(event);
      }}
      {...props}
    />
  );
}

export default StatefulTextField;
