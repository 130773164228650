import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export const LoginDescription: FunctionComponent = () => (
  <>
    <Typography variant="h4" component="h1" paragraph>
      Sign Up and Log In to Picardy for free.
    </Typography>

    <Typography variant="body2" component="p">
      We'll never post to your account without your permission.
    </Typography>

    <Typography variant="body2" component="p">
      Questions? Visit our <Link href="/help">HELP</Link> section or{' '}
      <Link href="/contact">CONTACT US</Link> directly.
    </Typography>
  </>
);

export default LoginDescription;
