import React from 'react';
import { TopBar } from '@picardy/picardy-design-system';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { styled } from '@material-ui/styles';

import { useUser } from '../../../common/components/User';

const LabelContainer = styled(props => <span {...props} />)({
  display: 'flex',
  alignItems: 'center',
});

const PaddedAccountCircleIcon = styled(AccountCircleIcon)({
  display: 'inline-block',
  marginRight: 4,
});

const Navigation: React.FunctionComponent = (/* loggedIn, roles*/) => {
  const user = useUser();
  const menuItems = [];

  if (user) {
    if (user.roles.student) {
      menuItems.push({
        href: '/dashboard',
        label: 'My Courses',
      });
    }
    if (user.roles.teacher_pending) {
      menuItems.push({
        href: '/application',
        label: 'My Application',
      });
    }
    if (user.roles.teacher) {
      menuItems.push({
        href: '/portal',
        label: 'My Classrooms',
      });
    }

    menuItems.push({
      href: '/help',
      label: 'Help',
    });

    if (user.roles.superuser) {
      menuItems.push({
        label: 'Admin',
        menuItems: [
          {
            href: '/admin/courses',
            label: 'Content',
          },
          {
            href: '/admin/users',
            label: 'Users',
          },
          {
            href: '/admin/applications',
            label: 'Applications',
          },
          {
            href: '/admin/coupons',
            label: 'Coupons',
          },
        ],
      });
    }

    if (!user.premium) {
      menuItems.push({
        href: '/pricing',
        label: 'Upgrade',
      });
    }

    menuItems.push({
      label: (
        <LabelContainer>
          <PaddedAccountCircleIcon fontSize="small" />
          {user.name}
        </LabelContainer>
      ),
      menuItems: [
        {
          href: '/progress/mine',
          label: 'My Stats',
        },
        {
          href: '/profile',
          label: 'My Profile',
        },
        {
          href: '/educators',
          label: 'Educators',
        },
        {
          href: '/thankyou',
          label: 'Our backers',
        },
        {
          href: '/logout',
          label: 'Log out',
        },
      ],
    });
  } else {
    // unauthenticated routes
    menuItems.push(
      {
        href: '/demo',
        label: 'Demo',
      },
      {
        href: '/course',
        label: 'Courses',
      },
      {
        href: '/educators',
        label: 'Educators',
      },
      {
        href: '/help',
        label: 'Help',
      },
      {
        href: '/login',
        label: 'Log in',
      },
      {
        href: '/login',
        label: 'Sign up',
      },
    );
  }

  return <TopBar menuItems={menuItems} />;
};

export default Navigation;
