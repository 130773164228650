import React from 'react';
import { omit } from 'lodash';
import { colors } from '@picardy/picardy-design-system';
import { styled } from '@material-ui/styles';
import SnackbarContent, {
  SnackbarContentProps,
} from '@material-ui/core/SnackbarContent';
import { styledBy } from '../../../util/material-ui';

type Props = Omit<SnackbarContentProps, 'variant'> & {
  variant: 'success' | 'info' | 'warning' | 'error';
};

const StyledSnackbarContent = styled((props: Props) => (
  <SnackbarContent {...omit(props, 'variant')} />
))((props: Props) => ({
  backgroundColor: styledBy<Props, string>(
    'variant',
    {
      success: colors.picardyChalkboardGreen,
      info: colors.picardyBlue,
      warning: colors.picardyYellow,
      error: colors.picardyRose,
    },
    colors.picardyBlue,
  )(props),
}));

export default StyledSnackbarContent;
