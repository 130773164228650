import React, { useState, FunctionComponent, ChangeEventHandler } from 'react';
import {
  withRouter,
  RouteComponentProps,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  Redirect,
} from 'react-router-dom';
import queryString from 'query-string';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper, { PaperProps } from '@material-ui/core/Paper';

import { isFormValid, validators } from '../../../../util/validators';
import CardForm from '../../../../common/components/CardForm';
import StatefulTextField from '../../../../common/components/StatefulTextField';
import StyledSnackbarContent from '../../../../common/components/StyledSnackbarContent';
import TermsReminder from '../TermsReminder';
import { SOCIAL_LOGIN_PATH, USERNAME_LOGIN_PATH } from '../../../constants';
import { resetPassword } from '../../../../api/user';

type Props = PaperProps & RouteComponentProps;

const SocialLoginLink = (props: Partial<RouterLinkProps>) => (
  <RouterLink to={SOCIAL_LOGIN_PATH} {...props} />
);

export const ResetPassword: FunctionComponent<Props> = (props: Props) => {
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [formTouched, setFormTouched] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const onTextFieldChange: ChangeEventHandler<HTMLInputElement> = event => {
    const target = event.target;
    setFormValues({ ...formValues, [target.name]: target.value });
  };

  return (
    <Paper {...props}>
      {!!formError && (
        <StyledSnackbarContent variant="error" message={formError} />
      )}

      {!formSuccess && (
        <CardForm
          method="post"
          action=""
          submitCTA="Save new password"
          onSubmit={async ev => {
            ev.preventDefault();
            setFormTouched(true);

            if (!isFormValid(formValues)) {
              return;
            }

            try {
              const { resetPasswordToken } = queryString.parse(
                props.location.search,
              );

              if (typeof resetPasswordToken !== 'string') {
                throw new Error('`resetPasswordToken` is not in the URL.');
              }

              await resetPassword({
                password: formValues.password,
                resetPasswordToken,
              });
              setFormSuccess(true);
              setFormError(null);
            } catch (err) {
              setFormSuccess(false);
              setFormError(
                'We weren’t able to reset your password. Check your email address or contact us if this problem continues.',
              );
            }
          }}
        >
          <StatefulTextField
            autoComplete="current-password"
            formTouched={formTouched}
            label="New Password"
            name="password"
            onChange={onTextFieldChange}
            required
            type="password"
            formValues={formValues}
            validator={validators.password}
          />
          <StatefulTextField
            autoComplete="current-password"
            formTouched={formTouched}
            label="Confirm New Password"
            name="confirmPassword"
            onChange={onTextFieldChange}
            required
            type="password"
            formValues={formValues}
            validator={validators.confirmPassword}
          />
        </CardForm>
      )}

      <List>
        {formSuccess && (
          <Redirect to={`${USERNAME_LOGIN_PATH}?passwordResetSuccess`} />
        )}
        <ListItem divider aria-hidden style={{ padding: 0 }} />
        <ListItem button component={() => <SocialLoginLink />}>
          <ListItemText primary="Log in or sign up" />
        </ListItem>
        <ListItem divider aria-hidden style={{ padding: 0 }} />
        <ListItemText primary={<TermsReminder />} />
      </List>
    </Paper>
  );
};

export { default as Description } from './Description';

export default withRouter(ResetPassword);
