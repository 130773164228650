import React, { FunctionComponent, ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { theme as picardyTheme } from '@picardy/picardy-design-system';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import {
  styled,
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/styles';

import Navigation from './Navigation';
import Footer from './Footer';
import { UserProvider } from '../User';
import { GlobalAlertProvider } from '../GlobalAlert';

const generateClassName = createGenerateClassName({ productionPrefix: 'c' });

const AppGrid = styled(Grid)(({ theme }: { theme: typeof picardyTheme }) => ({
  paddingTop: '1.5em',
  paddingBottom: '1.5em',
  minHeight: 'calc(100vh - 154px)',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  [theme.breakpoints.down('xs')]: {
    minHeight: 'calc(100vh - 146px)',
  },
}));

interface Props {
  spacing?: GridSpacing;
  children?: ReactNode;
}

const queryClient = new QueryClient({});

const AppWrapper: FunctionComponent<Props> = ({
  children,
  spacing = 2,
}: Props) => (
  <StylesProvider generateClassName={generateClassName}>
    <MuiThemeProvider theme={picardyTheme}>
      <ThemeProvider theme={picardyTheme}>
        <QueryClientProvider client={queryClient}>
          <GlobalAlertProvider>
            <UserProvider>
              <CssBaseline />
              <Navigation />
              <AppGrid container justifyContent="center" spacing={spacing}>
                {children}
              </AppGrid>
              <Footer />
            </UserProvider>
          </GlobalAlertProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default AppWrapper;
