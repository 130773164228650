import React from 'react';
import useFetch from 'use-http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { styled } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  theme as PicardyTheme,
  PicardyIcon,
} from '@picardy/picardy-design-system';

import { useUser } from '../../../common/components/User';

const TEXT_COLOR = '#97999b';
const BG_COLOR = '#51534a';

const FooterContainer = styled(Grid)(
  ({ theme }: { theme: typeof PicardyTheme }) => ({
    padding: '2.5em 2em',
    margin: '0 -1em',
    backgroundColor: BG_COLOR,
    alignItems: 'center',
    color: TEXT_COLOR,
    display: 'grid',
    gridGap: '0.5em',
    gridTemplateColumns: '120px auto',
    gridTemplateAreas: '"logo social"',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '120px 80px auto',
      gridTemplateAreas: '"logo social text-links"',
    },
  }),
);

const InlineFooterList = styled(List)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 0,
});

const FooterListLink = styled(({ children, ...props }: any) => (
  <ListItem button component={Link} {...props}>
    <Typography style={{ color: TEXT_COLOR, textTransform: 'uppercase' }}>
      {children}
    </Typography>
  </ListItem>
))({
  color: TEXT_COLOR,
  flex: 0,
  fontSize: '1em',
  padding: '0 0.5em',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const FooterSocialLinkList = styled(InlineFooterList)(
  ({ theme }: { theme: typeof PicardyTheme }) => ({
    gridArea: 'social',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      fontSize: '2em',
    },
  }),
);

const FooterTextLinkList = styled(InlineFooterList)(
  ({ theme }: { theme: typeof PicardyTheme }) => ({
    gridArea: 'text-links',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }),
);

export default function Footer() {
  const socialMedia = useFetch('/social-media', {}, []);
  const user = useUser();
  return (
    <FooterContainer>
      <div style={{ gridArea: '"logo"' }}>
        <Link href="/">
          <PicardyIcon fill="white" width={120} />
        </Link>
      </div>

      <FooterSocialLinkList>
        {socialMedia.data && (
          <>
            <FooterListLink
              href={`https://www.facebook.com/${socialMedia.data.facebook}`}
              style={{ padding: '0 0.25em' }}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </FooterListLink>
            <FooterListLink
              href={`https://www.twitter.com/${socialMedia.data.twitter}`}
              style={{ padding: '0 0.25em' }}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </FooterListLink>
            <FooterListLink
              href={`https://www.youtube.com/channel/${socialMedia.data.youtube}`}
              style={{ padding: '0 0.25em' }}
              target="_blank"
              rel="noopener"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </FooterListLink>
          </>
        )}
      </FooterSocialLinkList>

      <FooterTextLinkList>
        <FooterListLink href={user ? '/dashboard' : '/course'}>
          {user ? 'My Courses' : 'Courses'}
        </FooterListLink>
        {!user?.premium && (
          <FooterListLink href="/demo">View Demo</FooterListLink>
        )}
        {user?.roles.teacher_pending && (
          <FooterListLink href="/application">My Application</FooterListLink>
        )}
        {user?.roles.teacher && (
          <FooterListLink href="/portal">My Classrooms</FooterListLink>
        )}
        {!user?.premium && (
          <>
            <FooterListLink href="/educators">Educators</FooterListLink>
            <FooterListLink href="/educators">Students</FooterListLink>
          </>
        )}
        {user?.roles.student && !user?.premium && (
          <FooterListLink href="/pricing">Upgrade</FooterListLink>
        )}
        <FooterListLink href="/thankyou">Our Backers</FooterListLink>
        <FooterListLink href="/about">About</FooterListLink>
        <FooterListLink href="/legal">Legal</FooterListLink>
        <FooterListLink href="/help">Help</FooterListLink>
        <FooterListLink href="/contact">Contact</FooterListLink>
      </FooterTextLinkList>
    </FooterContainer>
  );
}
