import React, { FunctionComponent, useState, ChangeEventHandler } from 'react';

import { isFormValid, validators } from '../../../../util/validators';
import CardForm from '../../../../common/components/CardForm';
import StatefulTextField from '../../../../common/components/StatefulTextField';

interface Props {
  action: string;
}

const SignupForm: FunctionComponent<Props> = ({ action }: Props) => {
  const [formValues, setFormValues] = useState({ username: '', password: '' });
  const [formTouched, setFormTouched] = useState(false);
  const onTextFieldChange: ChangeEventHandler<HTMLInputElement> = event => {
    const target: { name: string; value: string } = event.target;
    setFormValues({ ...formValues, [target.name]: target.value });
  };

  return (
    <CardForm
      action={action}
      submitCTA="Sign up"
      method="post"
      onSubmit={ev => {
        if (!isFormValid(formValues)) {
          ev.preventDefault();
          setFormTouched(true);
        }
      }}
    >
      <StatefulTextField
        formTouched={formTouched}
        label="Email"
        name="username"
        onChange={onTextFieldChange}
        required
        type="email"
        formValues={formValues}
        validator={validators.email}
      />
      <StatefulTextField
        autoComplete="current-password"
        formTouched={formTouched}
        label="Password"
        name="password"
        onChange={onTextFieldChange}
        required
        type="password"
        formValues={formValues}
        validator={validators.password}
      />
      <StatefulTextField
        autoComplete="current-password"
        formTouched={formTouched}
        label="Confirm Password"
        name="confirmPassword"
        onChange={onTextFieldChange}
        required
        type="password"
        formValues={formValues}
        validator={validators.confirmPassword}
      />
    </CardForm>
  );
};

export default SignupForm;
