import React, { useState, FunctionComponent, ChangeEventHandler } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { isFormValid, validators } from '../../../../util/validators';
import CardForm from '../../../../common/components/CardForm';
import StatefulTextField from '../../../../common/components/StatefulTextField';
import StyledSnackbarContent from '../../../../common/components/StyledSnackbarContent';
import TermsReminder from '../TermsReminder';
import { SOCIAL_LOGIN_PATH } from '../../../constants';
import { generateResetPassword } from '../../../../api/user';

interface Props extends PaperProps {}

const SocialLoginLink = (props: Partial<RouterLinkProps>) => (
  <RouterLink to={SOCIAL_LOGIN_PATH} {...props} />
);

export const ForgotPassword: FunctionComponent<Props> = props => {
  const [formValues, setFormValues] = useState({ email: '' });
  const [formTouched, setFormTouched] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const onTextFieldChange: ChangeEventHandler<HTMLInputElement> = event => {
    const target = event.target;
    setFormValues({ ...formValues, [target.name]: target.value });
  };

  return (
    <Paper {...props}>
      {!!formError && (
        <StyledSnackbarContent variant="error" message={formError} />
      )}

      {!formSuccess && (
        <CardForm
          action=""
          method="post"
          submitCTA="Request password reset"
          onSubmit={async ev => {
            ev.preventDefault();
            setFormTouched(true);

            if (!isFormValid(formValues)) {
              return;
            }

            try {
              await generateResetPassword(formValues);
              setFormSuccess(true);
              setFormError(null);
            } catch (err) {
              setFormSuccess(false);
              setFormError(
                'We weren’t able to reset your password. Check your email address or contact us if this problem continues.',
              );
            }
          }}
        >
          <StatefulTextField
            formTouched={formTouched}
            label="Email address"
            name="email"
            onChange={onTextFieldChange}
            required
            type="email"
            helperText="Be sure to use the email address for your Picardy account."
            formValues={formValues}
            validator={validators.email}
          />
        </CardForm>
      )}

      <List>
        {formSuccess && (
          <ListItem>
            <ListItemText
              primary={
                <>
                  <Typography variant="h5" component="h2" paragraph>
                    A password reset email has been sent to{' '}
                    <strong>{formValues.email}</strong>.
                  </Typography>
                  <Typography variant="body1" component="p">
                    You'll have 24 hours to complete the password reset. If you
                    don't see the email shortly, try again or{' '}
                    <Link href="/contact">contact us</Link> to let us know.
                  </Typography>
                </>
              }
            />
          </ListItem>
        )}
        <ListItem divider aria-hidden style={{ padding: 0 }} />
        <ListItem button component={SocialLoginLink}>
          <ListItemText primary="Log in or sign up" />
        </ListItem>
        <ListItem divider aria-hidden style={{ padding: 0 }} />
        <ListItemText primary={<TermsReminder />} />
      </List>
    </Paper>
  );
};

export { Description } from '../ResetPassword';

export default ForgotPassword;
