import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { styled } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import SocialLogin, {
  Description as SocialLoginDescription,
} from './SocialLogin';
import PasswordLogin, {
  Description as PasswordLoginDescription,
} from './PasswordLogin';
import ForgotPassword, {
  Description as ForgotPasswordDescription,
} from './ForgotPassword';
import ResetPassword, {
  Description as ResetPasswordDescription,
} from './ResetPassword';
import {
  SOCIAL_LOGIN_PATH,
  USERNAME_LOGIN_PATH,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
} from '../../constants';
import { theme as PicardyTheme } from '@picardy/picardy-design-system';

const LoginSectionWrapper = styled(Grid)(
  ({ theme }: { theme: typeof PicardyTheme }) => ({
    [theme.breakpoints.up('md')]: {
      marginTop: '2em',
      marginBottom: '5em',
      paddingRight: '1em',
    },
  }),
);

const DescriptionLoginSectionWrapper = styled(LoginSectionWrapper)(
  ({ theme }: { theme: typeof PicardyTheme }) => ({
    paddingTop: '1.5em',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
);

const LoginPage: FunctionComponent = () => (
  <>
    <DescriptionLoginSectionWrapper item lg={4} md={4}>
      <Route
        exact
        path={SOCIAL_LOGIN_PATH}
        component={SocialLoginDescription}
      />
      <Route path={USERNAME_LOGIN_PATH} component={PasswordLoginDescription} />
      <Route
        path={FORGOT_PASSWORD_ROUTE}
        component={ForgotPasswordDescription}
      />
      <Route path={RESET_PASSWORD_ROUTE} component={ResetPasswordDescription} />
    </DescriptionLoginSectionWrapper>

    <LoginSectionWrapper item lg={4} md={6}>
      <Route exact path={SOCIAL_LOGIN_PATH} component={SocialLogin} />
      <Route path={USERNAME_LOGIN_PATH} component={PasswordLogin} />
      <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
      <Route path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
    </LoginSectionWrapper>
  </>
);

export default LoginPage;
