import React, { FunctionComponent } from 'react';
import {
  withRouter,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  RouteComponentProps,
  Route,
} from 'react-router-dom';
import queryString from 'query-string';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import TermsReminder from '../TermsReminder';
import StyledSnackbarContent from '../../../../common/components/StyledSnackbarContent';
import { authUrl } from '../../../utils';
import {
  SOCIAL_LOGIN_PATH,
  USERNAME_LOGIN_PATH,
  USERNAME_SIGNUP_PATH,
  FORGOT_PASSWORD_ROUTE,
} from '../../../constants';

const SocialLoginLink = (props: Partial<RouterLinkProps>) => (
  <RouterLink to={SOCIAL_LOGIN_PATH} {...props} />
);
const ForgotPasswordLink = (props: Partial<RouterLinkProps>) => (
  <RouterLink to={FORGOT_PASSWORD_ROUTE} {...props} />
);

const PasswordLogin: FunctionComponent<PaperProps & RouteComponentProps> = (
  props: PaperProps & RouteComponentProps,
) => {
  const {
    loginFailed,
    passwordResetSuccess,
    redirect_to: redirectToParam,
  } = queryString.parse(location.search);
  const hasLoginError = loginFailed !== undefined;
  const hasPasswordResetSuccess = passwordResetSuccess !== undefined;
  let redirectTo: string | undefined;
  if (typeof redirectToParam === 'string') {
    redirectTo = redirectToParam;
  }
  return (
    <Paper>
      {hasPasswordResetSuccess && (
        <StyledSnackbarContent
          variant="success"
          message="Password successfully reset. Enter your email and your new password to sign in."
        />
      )}
      {hasLoginError && (
        <StyledSnackbarContent
          variant="error"
          message="Login failed. Please check your password and try again. If this persists, please contact us."
        />
      )}

      <Tabs
        value={props.location.pathname}
        onChange={(_ev, value) => props.history.push(value)}
      >
        <Tab label="Log in" value={USERNAME_LOGIN_PATH} />
        <Tab label="Sign up" value={USERNAME_SIGNUP_PATH} />
      </Tabs>

      <Route
        exact
        path={USERNAME_LOGIN_PATH}
        render={p => (
          <LoginForm action={authUrl('/auth/password', redirectTo)} {...p} />
        )}
      />
      <Route
        path={USERNAME_SIGNUP_PATH}
        render={p => (
          <SignupForm
            action={authUrl('/auth/password/signup', redirectTo)}
            {...p}
          />
        )}
      />

      <List>
        <ListItem divider aria-hidden style={{ padding: 0 }} />
        <ListItem button component={SocialLoginLink}>
          <ListItemText primary="Use Facebook or Google to log in" />
        </ListItem>
        <ListItem button component={ForgotPasswordLink}>
          <ListItemText primary="Forgot your password?" />
        </ListItem>
        <ListItem divider aria-hidden style={{ padding: 0 }} />
        <ListItemText primary={<TermsReminder />} />
      </List>
    </Paper>
  );
};

export { default as Description } from '../LoginDescription';

export default withRouter(PasswordLogin);
