import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import omit from 'lodash.omit';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

type GlobalAlert = {
  content: string;
} & Omit<AlertProps, 'children'>;

interface IGlobalAlertContext {
  current: GlobalAlert | undefined;
  update: (globalAlert: GlobalAlert) => void;
  clear: () => void;
}

export const GlobalAlertContext = createContext<IGlobalAlertContext>({
  current: undefined,
  update: () => {
    throw new Error('GlobalAlertProvider did not initialize the context.');
  },
  clear: () => {
    throw new Error('GlobalAlertProvider did not initialize the context.');
  },
});

export const useGlobalAlert = () => useContext(GlobalAlertContext);

export function GlobalAlertDisplay() {
  const { current, clear } = useGlobalAlert();
  return (
    <>
      {current && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={!!current}
          autoHideDuration={10000}
          onClose={() => clear()}
        >
          <Alert
            {...omit(current, ['content', 'onClose'])}
            onClose={event => {
              clear();
              if (current.onClose) current.onClose(event);
            }}
          >
            <Typography variant="body2">{current.content}</Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export function GlobalAlertProvider({ children }: { children: ReactNode }) {
  const [globalAlert, setGlobalAlert] = useState<GlobalAlert>();
  const clearGlobalAlert = useCallback(
    () => setGlobalAlert(undefined),
    [setGlobalAlert],
  );
  return (
    <GlobalAlertContext.Provider
      value={{
        current: globalAlert,
        update: setGlobalAlert,
        clear: clearGlobalAlert,
      }}
    >
      {children}
      <GlobalAlertDisplay />
    </GlobalAlertContext.Provider>
  );
}
